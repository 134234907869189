define('app/symbols/symbolsDirective',[
		'app/symbols/module',
		'angular',
], function(symbols, angular, Modernizr) {

	var body = angular.element('body');

	symbols.directive('trendingSymbols', ['$timeout', function($timeout) {
		return {
			restrict: 'E',
			templateUrl: 'app/symbols/trending-symbols.html'
		}
	}]);

	symbols.directive('symbolsLink', [function() {
		return {
			restrict: 'C',
			link: function(scope, elem, attrs) {
				elem.on('click', function() {
					body.removeClass('is-expanded-nav');
				});
			}
		}
	}])
});

