define('app/symbols/symbolsController',[
		'app/symbols/module',
		'angular'
], function(symbols, angular) {

	symbols.controller('SymbolsController', [
		'$scope',
		'SymbolsService',
		'$timeout',
	function($scope, SymbolsService, $timeout) {

		SymbolsService.getTrendingSymbols().success(function(data) {
			$timeout(function() {
				$scope.trendingSymbols = data.symbols;
			});
		});

	}]);
});

