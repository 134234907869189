define('app/symbols/symbolsService',[
		'app/symbols/module',
		'angular',
		'jquery'
], function(symbols, angular, $) {

	symbols.factory('SymbolsService', ['$http', function($http) {
		return {
			getTrendingSymbols: function() {
				return $.ajax({
					url: 'https://api.stocktwits.com/api/2/trending/symbols.json',
					dataType: 'jsonp'
				});
			}
		}
	}]);
});

