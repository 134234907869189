define('app/stocky',[
	'require',
	'jquery',
	'angular',
	'angularRoute',
	'app/shared/header/header',
	'app/symbols/symbols',
	'app/symbol/symbol'
], function(require, $, angular) {

	var stocky = angular.module('stocky', [
		'ngRoute',
		'stocky.header',
		'stocky.symbols',
		'stocky.symbol'
	]);

	return stocky;
});

