define('app/symbol/symbolService',[
		'app/symbol/module',
		'angular',
		'jquery'
], function(symbol, angular, $) {

	symbol.factory('SymbolService', ['$http', function($http) {
		return {
			getSymbolStream: function(id) {
				return $.ajax({
					url: 'https://api.stocktwits.com/api/2/streams/symbol/' + id + '.json',
					dataType: 'jsonp'
				});
			}
		}
	}]);
});

