define('app/shared/header/headerDirective',[
	'app/shared/header/module',
	'angular'
], function(header, angular) {

	var body = angular.element('body');

	header.directive('mainHeader', [function() {
		return {
			restrict: 'E',
			templateUrl: 'app/shared/header/header.html',
			controller: ['$scope', 'SymbolsService', '$timeout', function($scope, SymbolsService, $timeout) {
				SymbolsService.getTrendingSymbols().success(function(data) {
					$timeout(function() {
						$scope.trendingSymbols = data.symbols;
					});
				});

				$scope.toggleExpand = function(ev) {
					ev.stopPropagation();
					body.toggleClass('is-expanded-nav');
				};
			}],
			link: function(scope, elem, attrs) {

			}
		}
	}]);
});

