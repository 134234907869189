define('app/stocky.routes',['app/stocky'], function(stocky) {
	'use strict';
	var $routeProviderReference;

	return stocky.config([
		'$routeProvider',
		'$locationProvider',
	function($routeProvider, $locationProvider) {

		$routeProvider.when('/', {
			templateUrl: 'app/symbols/symbols.html',
			controller: 'SymbolsController',
		}).when('/symbol/:symbol', {
			templateUrl: 'app/symbol/symbol.html',
			controller: 'SymbolController'
		});

	}]);
});

