define('app/shared/header/headerController',[
	'app/shared/header/module',
	'angular'
], function(header, angular) {

	header.controller('HeaderController', [
		'$scope',
		'SymbolsService',
		'$timeout',
	function($scope, SymbolsService, $timeout) {

	}]);
});

