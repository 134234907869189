define('app/symbol/symbolDirective',[
		'app/symbol/module',
		'angular',
], function(symbol, angular) {

	symbol.directive('symbol', [function() {
		return {
			restrict: 'A'
		}
	}]);
});

