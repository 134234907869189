require.config({
	/* Base Url is libs folder for simpler references */
	baseUrl: '/app/lib',

	paths: {
		'app': '../',
		'jquery': 'jquery',
		'angular': 'angular',
		'angularRoute': 'angular-route'
	},

	shim: {
		'angular': {
			'exports': 'angular',
			'deps': ['jquery'],
		 },
		'angularRoute': ['angular']
	},
	waitSeconds: 15
});

/* Grab the global modernizr object and wrap it in a define call */
define('modernizr', [],function() {
	"use strict";
	return window.Modernizr;
});

require([
	'angular',
	'app/stocky',
	'app/stocky.routes'
	], function(ng, Stocky) {

		var $html = ng.element(document.getElementsByTagName('html')[0]);

		ng.element().ready(function() {
			ng.bootstrap(document, ['stocky']);
		});
	}
);

define("main", function(){});

