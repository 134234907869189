define('app/symbol/symbolController',[
		'app/symbol/module',
		'angular'
], function(symbol, angular) {

	symbol.controller('SymbolController', [
			'$scope',
			'SymbolService',
			'$timeout',
			'$routeParams',
	function($scope, SymbolService, $timeout, $routeParams) {
		$scope.symbol = $routeParams.symbol;

		SymbolService.getSymbolStream($scope.symbol).success(function(data) {
			$timeout(function() {
				$scope.symbolStreams = data.messages;
			});
		});
	}]);
});

